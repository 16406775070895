<template>
  <div class="h-full">
    <h2>Formulaires</h2>

    <v-row class="fill-height">
      <v-col cols="12" md="9">
        <draggable v-model="formFields" :options="dragOptions" class="form-builder">
          <template v-for="(field, index) in formFields">
            <v-row>
              <v-col cols="12" md="12">
                <v-card :key="index" class="rounded-xl" elevation="2">
                  <v-dialog v-model="dialogPutService" max-width="950">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" fab icon @click="editItem(field)"
                        ><v-icon>mdi-cogs</v-icon></v-btn
                      >
                    </template>
                    <v-card>
                      {{ editFields }}
                    </v-card>
                  </v-dialog>

                  {{ field }}

                  <v-card-title>{{ field.label }}</v-card-title>
                  <v-card-text>
                    <component
                      :label="field.label"
                      :is="field.type"
                      v-model="field.value"
                      :items="field.items"
                      :inset="field.inset"
                      outlined
                    ></component>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </draggable>
        <v-btn class="white--text my-5" color="EoleGreen" block>Sauvegarder</v-btn>
      </v-col>
      <v-col cols="12" md="3">
        <v-card class="rounded-xl" elevation="3" height="100%">
          <v-btn
            @click="
              addField({
                type: 'VTextField',
                label: 'Champs de texte ajouté',
                value: 'ok',
              })
            "
            >Ajouter texte</v-btn
          >
          <v-btn
            @click="
              addField({
                type: 'VCheckbox',
                label: 'Case à cocher ajoutée',
                value: false,
              })
            "
            >Ajouter case à cocher</v-btn
          >
          <v-btn
            @click="
              addField({
                type: 'VTextarea',
                label: 'Zone de texte ajoutée',
                value: 'ok',
              })
            "
            >Ajouter zone de texte</v-btn
          >

          <v-btn
            @click="
              addField({
                type: 'VSwitch',
                label: 'Interrupteur',
                value: false,
                inset: true,
              })
            "
            >Ajouter interrupteur</v-btn
          >

          <v-btn
            @click="
              addField({
                type: 'VCheckbox',
                label: 'Case à cocher ajoutée',
                value: false,
              })
            "
            >Ajouter case à cocher</v-btn
          >
          <v-btn
            @click="
              addField({
                type: 'VRadioGroup',
                label: 'Groupe de cases à cocher',
                value: null,
                options: [
                  { label: 'Option 1', value: 'option1' },
                  { label: 'Option 2', value: 'option2' },
                  { label: 'Option 3', value: 'option3' },
                ],
              })
            "
            >Ajouter groupe de cases à cocher</v-btn
          >

          <v-btn
            @click="
              addField({
                type: 'VSlider',
                label: 'Curseur',
                value: 50,
                min: 0,
                max: 100,
              })
            "
            >Ajouter curseur</v-btn
          >
          <v-btn
            @click="
              addField({
                type: 'VSelect',
                label: 'Sélection',
                value: null,
                items: ['Option 1', 'Option 2', 'Option 3'],
              })
            "
            >Ajouter sélection</v-btn
          >

          <v-btn
            @click="
              addField({
                type: 'VDatePicker',
                label: 'Sélecteur de date',
                value: null,
              })
            "
            >Ajouter sélecteur de date</v-btn
          >
          <v-btn
            @click="
              addField({
                type: 'VTimePicker',
                label: 'Sélecteur d\'heure',
                value: null,
              })
            "
            >Ajouter sélecteur d'heure</v-btn
          >
          <v-btn
            @click="
              addField({
                type: 'VAutocomplete',
                label: 'Autocomplétion',
                value: null,
                items: ['Option 1', 'Option 2', 'Option 3'],
              })
            "
            >Ajouter autocomplétion</v-btn
          >
          <v-btn
            @click="
              addField({
                type: 'VSlider',
                label: 'Curseur',
                value: 50,
                min: 0,
                max: 100,
                step: 5,
              })
            "
            >Ajouter curseur avec étapes</v-btn
          >
          <v-btn
            @click="
              addField({
                type: 'VSelect',
                label: 'Sélection multiple',
                value: [],
                multiple: true,
                items: ['Option 1', 'Option 2', 'Option 3'],
              })
            "
            >Ajouter sélection multiple</v-btn
          >
          <v-btn
            @click="
              addField({
                type: 'VCombobox',
                label: 'Combobox',
                value: null,
                items: ['Option 1', 'Option 2', 'Option 3'],
              })
            "
            >Ajouter combobox</v-btn
          >
          <v-btn
            @click="
              addField({
                type: 'VChipGroup',
                label: 'Groupe de puces',
                value: [],
                chips: ['Puce 1', 'Puce 2', 'Puce 3'],
              })
            "
            >Ajouter groupe de puces</v-btn
          >
          <v-btn
            @click="
              addField({
                type: 'VSlider',
                label: 'Curseur avec plage',
                value: [25, 75],
                min: 0,
                max: 100,
                range: true,
              })
            "
            >Ajouter curseur avec plage</v-btn
          >
          <v-btn
            @click="
              addField({
                type: 'VColorPicker',
                label: 'Sélecteur de couleur',
                value: '#ff0000',
              })
            "
            >Ajouter sélecteur de couleur</v-btn
          >
          <v-btn
            @click="
              addField({
                type: 'VRating',
                label: 'Évaluation',
                value: 3,
                icon: 'star',
                iconSize: 24,
                max: 5,
              })
            "
            >Ajouter évaluation</v-btn
          >
          <v-divider></v-divider>
          <span class="mx-5">
            {{ formFields }}
          </span>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import draggable from "vuedraggable"
import {
  VTextField,
  VCheckbox,
  VTextarea,
  VSwitch,
  VRadioGroup,
  VSlider,
  VSelect,
  VDatePicker,
  VTimePicker,
  VAutocomplete,
  VCombobox,
  VChipGroup,
  VColorPicker,
  VRating,
} from "vuetify/lib"

export default {
  components: {
    draggable,
    VTextField,
    VCheckbox,
    VTextarea,
    VSwitch,
    VRadioGroup,
    VSlider,
    VSelect,
    VDatePicker,
    VTimePicker,
    VAutocomplete,
    VCombobox,
    VChipGroup,
    VColorPicker,
    VRating,
  },
  data() {
    return {
      editFields: {},
      formFields: [
        { type: "VTextField", label: "Champ de texte", value: "" },
        { type: "VCheckbox", label: "Case à cocher", value: false },
        { type: "VTextarea", label: "Zone de texte", value: "" },
        { type: "VSelect", label: "Zone de texte", value: "t", items: ["Option 1", "Option 2", "Option 3"] },
      ],
      dragOptions: {
        animation: 150,
        group: "formFields",
      },
    }
  },
  methods: {
    editItem(item) {
      this.editFields = { ...item }
    },
    addField(field) {
      this.formFields.push(field)
    },
    submitForm() {
      const formData = this.formFields.map(field => ({
        label: field.label,
        value: field.value,
      }))

      // Faites quelque chose avec les données du formulaire
      console.log(formData)
    },
  },
}
</script>

<style scoped></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full"},[_c('h2',[_vm._v("Formulaires")]),_c('v-row',{staticClass:"fill-height"},[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('draggable',{staticClass:"form-builder",attrs:{"options":_vm.dragOptions},model:{value:(_vm.formFields),callback:function ($$v) {_vm.formFields=$$v},expression:"formFields"}},[_vm._l((_vm.formFields),function(field,index){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{key:index,staticClass:"rounded-xl",attrs:{"elevation":"2"}},[_c('v-dialog',{attrs:{"max-width":"950"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","icon":""},on:{"click":function($event){return _vm.editItem(field)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cogs")])],1)]}}],null,true),model:{value:(_vm.dialogPutService),callback:function ($$v) {_vm.dialogPutService=$$v},expression:"dialogPutService"}},[_c('v-card',[_vm._v(" "+_vm._s(_vm.editFields)+" ")])],1),_vm._v(" "+_vm._s(field)+" "),_c('v-card-title',[_vm._v(_vm._s(field.label))]),_c('v-card-text',[_c(field.type,{tag:"component",attrs:{"label":field.label,"items":field.items,"inset":field.inset,"outlined":""},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})],1)],1)],1)],1)]})],2),_c('v-btn',{staticClass:"white--text my-5",attrs:{"color":"EoleGreen","block":""}},[_vm._v("Sauvegarder")])],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-card',{staticClass:"rounded-xl",attrs:{"elevation":"3","height":"100%"}},[_c('v-btn',{on:{"click":function($event){return _vm.addField({
              type: 'VTextField',
              label: 'Champs de texte ajouté',
              value: 'ok',
            })}}},[_vm._v("Ajouter texte")]),_c('v-btn',{on:{"click":function($event){return _vm.addField({
              type: 'VCheckbox',
              label: 'Case à cocher ajoutée',
              value: false,
            })}}},[_vm._v("Ajouter case à cocher")]),_c('v-btn',{on:{"click":function($event){return _vm.addField({
              type: 'VTextarea',
              label: 'Zone de texte ajoutée',
              value: 'ok',
            })}}},[_vm._v("Ajouter zone de texte")]),_c('v-btn',{on:{"click":function($event){return _vm.addField({
              type: 'VSwitch',
              label: 'Interrupteur',
              value: false,
              inset: true,
            })}}},[_vm._v("Ajouter interrupteur")]),_c('v-btn',{on:{"click":function($event){return _vm.addField({
              type: 'VCheckbox',
              label: 'Case à cocher ajoutée',
              value: false,
            })}}},[_vm._v("Ajouter case à cocher")]),_c('v-btn',{on:{"click":function($event){return _vm.addField({
              type: 'VRadioGroup',
              label: 'Groupe de cases à cocher',
              value: null,
              options: [
                { label: 'Option 1', value: 'option1' },
                { label: 'Option 2', value: 'option2' },
                { label: 'Option 3', value: 'option3' } ],
            })}}},[_vm._v("Ajouter groupe de cases à cocher")]),_c('v-btn',{on:{"click":function($event){return _vm.addField({
              type: 'VSlider',
              label: 'Curseur',
              value: 50,
              min: 0,
              max: 100,
            })}}},[_vm._v("Ajouter curseur")]),_c('v-btn',{on:{"click":function($event){return _vm.addField({
              type: 'VSelect',
              label: 'Sélection',
              value: null,
              items: ['Option 1', 'Option 2', 'Option 3'],
            })}}},[_vm._v("Ajouter sélection")]),_c('v-btn',{on:{"click":function($event){return _vm.addField({
              type: 'VDatePicker',
              label: 'Sélecteur de date',
              value: null,
            })}}},[_vm._v("Ajouter sélecteur de date")]),_c('v-btn',{on:{"click":function($event){return _vm.addField({
              type: 'VTimePicker',
              label: 'Sélecteur d\'heure',
              value: null,
            })}}},[_vm._v("Ajouter sélecteur d'heure")]),_c('v-btn',{on:{"click":function($event){return _vm.addField({
              type: 'VAutocomplete',
              label: 'Autocomplétion',
              value: null,
              items: ['Option 1', 'Option 2', 'Option 3'],
            })}}},[_vm._v("Ajouter autocomplétion")]),_c('v-btn',{on:{"click":function($event){return _vm.addField({
              type: 'VSlider',
              label: 'Curseur',
              value: 50,
              min: 0,
              max: 100,
              step: 5,
            })}}},[_vm._v("Ajouter curseur avec étapes")]),_c('v-btn',{on:{"click":function($event){return _vm.addField({
              type: 'VSelect',
              label: 'Sélection multiple',
              value: [],
              multiple: true,
              items: ['Option 1', 'Option 2', 'Option 3'],
            })}}},[_vm._v("Ajouter sélection multiple")]),_c('v-btn',{on:{"click":function($event){return _vm.addField({
              type: 'VCombobox',
              label: 'Combobox',
              value: null,
              items: ['Option 1', 'Option 2', 'Option 3'],
            })}}},[_vm._v("Ajouter combobox")]),_c('v-btn',{on:{"click":function($event){return _vm.addField({
              type: 'VChipGroup',
              label: 'Groupe de puces',
              value: [],
              chips: ['Puce 1', 'Puce 2', 'Puce 3'],
            })}}},[_vm._v("Ajouter groupe de puces")]),_c('v-btn',{on:{"click":function($event){return _vm.addField({
              type: 'VSlider',
              label: 'Curseur avec plage',
              value: [25, 75],
              min: 0,
              max: 100,
              range: true,
            })}}},[_vm._v("Ajouter curseur avec plage")]),_c('v-btn',{on:{"click":function($event){return _vm.addField({
              type: 'VColorPicker',
              label: 'Sélecteur de couleur',
              value: '#ff0000',
            })}}},[_vm._v("Ajouter sélecteur de couleur")]),_c('v-btn',{on:{"click":function($event){return _vm.addField({
              type: 'VRating',
              label: 'Évaluation',
              value: 3,
              icon: 'star',
              iconSize: 24,
              max: 5,
            })}}},[_vm._v("Ajouter évaluation")]),_c('v-divider'),_c('span',{staticClass:"mx-5"},[_vm._v(" "+_vm._s(_vm.formFields)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }